import { createContext, useState, useEffect, useContext } from "react";
import { notification } from "antd";
import axios from "axios";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import { doc, getDoc, onSnapshot } from "firebase/firestore";

import AuthContext from "./AuthContext";
import { API_URL } from "@config";
import { Client, ClientSettings, ContactPerson } from "@models/client.model";
import { QuestionPostStatistics } from "@models/question_post_statistics.model";
import { ClientLog } from "@models/client_log.model";
import { QuestionPost } from "@models/question_post";
import CompanyContext from "./CompanyContext";
import { db } from "../firebase";

type ClientContext = {
  clients: Client[] | undefined;
  stats: QuestionPostStatistics | undefined;
  clientsLoading: boolean;
  statsLoading: boolean;
  error: null | string;
  activate: (id: string, data: ActiveInput) => Promise<void>;
  deactivate: (clientId: string) => Promise<void>;
  update: (clientId: string, data: UpdateInput) => Promise<void>;
  refresh: (clientIds: string[]) => Promise<void>;
  getLogs: (clientId: string) => Promise<ClientLog[] | undefined>;
  getQuestionPostList: (clientId: string) => Promise<QuestionPost[]>;
  sendNotification: (clientId: string) => Promise<void>;
};

export type ActiveInput = {
  settings: ClientSettings;
  contacts: ContactPerson[];
};

export type UpdateInput = {
  settings?: ClientSettings;
  contacts?: ContactPerson[];
};

const ClientContext = createContext<ClientContext>({} as any);

export const ClientProvider: React.FC = ({ children }) => {
  const { user } = useContext(AuthContext);
  const { company } = useContext(CompanyContext);
  const [clientsLoading, setClientsLoading] = useState(true);
  const [statsLoading, setStatsLoading] = useState(true);
  const [clients, setClients] = useState<Client[]>();
  const [stats, setStats] = useState<QuestionPostStatistics>();
  const [error, setError] = useState<string | null>(null);

  const router = useRouter();

  useEffect(() => {
    if (user) {
      loadClients();
      // loadStats();
    }
  }, [user]);

  useEffect(() => {
    if (!company) return;

    const unsub = onSnapshot(doc(db, "clients_stats", company.id), (doc) => {
      setStatsLoading(false);

      const data = doc.data() as QuestionPostStatistics;
      setStats(data);
    });

    return unsub;
  }, [company]);

  const loadClients = async () => {
    setClientsLoading(true);
    const token = Cookies.get("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const { data } = await axios.get<Client[]>(`${API_URL}/clients`, {
        headers,
      });
      setClients(data);
    } catch (error: any) {
      // FIXME log
      notification.error({ message: error.message });
    }

    setClientsLoading(false);
  };

  const sendNotification = async (clientId: string) => {
    const token = Cookies.get("token");
    if (!token) return;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await axios.post(
        `${API_URL}/clients/${clientId}/send-notification`,
        null,
        {
          headers,
        }
      );
      notification.success({ message: "Success" });
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  const deactivate = async (clientId: string) => {
    const token = Cookies.get("token");
    if (!token) return;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await axios.patch(`${API_URL}/clients/${clientId}/deactivate`, null, {
        headers,
      });

      const updatedList = clients?.map((client) => {
        if (client.id === clientId) {
          return { ...client, is_active: false };
        }

        return client;
      });

      setClients(updatedList);
    } catch (error: any) {
      setError(error);
      setError(null);
    }
  };

  const activate = async (id: string, data: ActiveInput) => {
    const token = Cookies.get("token");
    if (!token) return;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await axios.post(`${API_URL}/clients/${id}/activate`, data, { headers });
      const updatedList = clients?.map((client) => {
        if (client.id === id) {
          return { ...client, ...data, is_active: true };
        }

        return client;
      });

      setClients(updatedList);
      router.push("/klanten");
    } catch (error: any) {
      setError(error.message);
      setError(null);
      throw error;
    }
  };

  const refresh = async (clientIds: string[]) => {
    const token = Cookies.get("token");
    if (!token) return;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    if (clientIds.length === 0) return;

    try {
      const body = {
        clients: clientIds,
      };

      // FIXME (should return a object with stats)
      await axios.put(`${API_URL}/clients/refresh`, body, { headers });
    } catch (error: any) {
      setError(error.message);
      setError(null);
    }
  };

  const update = async (clientId: string, data: UpdateInput) => {
    const token = Cookies.get("token");
    if (!token) return;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await axios.put(`${API_URL}/clients/${clientId}/update`, data, {
        headers,
      });

      const updatedList = clients?.map((client) => {
        if (client.id === clientId) {
          return { ...client, ...data };
        }

        return client;
      });

      setClients(updatedList);
    } catch (error: any) {
      setError(error.message);
      setError(null);
      throw error;
    }
  };

  const getLogs = async (
    clientId: string
  ): Promise<ClientLog[] | undefined> => {
    const token = Cookies.get("token");
    if (!token) return;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const resp = await axios.get<ClientLog[]>(
        `${API_URL}/clients/${clientId}/logs`,
        { headers }
      );

      return resp.data;
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  const getQuestionPostList = async (
    clientId: string
  ): Promise<QuestionPost[]> => {
    const token = Cookies.get("token");
    if (!token) throw new Error("Not Authorized");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const { data } = await axios.get<QuestionPost[]>(
        `${API_URL}/clients/${clientId}/question-posts`,
        { headers }
      );

      return data;
    } catch (error: any) {
      // Sentry log
    }
    return [];
  };

  return (
    <ClientContext.Provider
      value={{
        clients,
        clientsLoading,
        statsLoading,
        stats,
        activate,
        error,
        deactivate,
        update,
        getLogs,
        getQuestionPostList,
        refresh,
        sendNotification,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export default ClientContext;
