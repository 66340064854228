import { LoginInput } from "pages/api/login";
import { createContext, useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";

import { NEXT_URL, API_URL } from "@config";
import { useRouter } from "next/router";
import { RegisterInput } from "pages/api/auth/register";
import { User } from "models/user.model";
import dayjs from "dayjs";

type AuthContext = {
  login: (input: LoginInput) => Promise<void>;
  logout: () => Promise<void>;
  register: (input: RegisterInput) => Promise<void>;
  errorCode: number | null;
  loading: boolean;
  user: User | null;
  changePassword: (password: string) => Promise<void>;
  updateUser: (input: {
    first_name: string;
    last_name: string;
  }) => Promise<void>;
};

const AuthContext = createContext<AuthContext>({} as any);

export const AuthProvider: React.FC = ({ children }) => {
  const [errorCode, setErrorCode] = useState<number | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const router = useRouter();

  useEffect(() => {
    checkIfUserLoggedIn();
  }, []);

  const checkIfUserLoggedIn = async () => {
    await getUserInfo();
    setLoading(false);
  };

  const login = async (input: LoginInput): Promise<void> => {
    try {
      const { data } = await axios.post<{ token: string }>(
        `${API_URL}/auth/login`,
        input
      );

      onSignInSuccess(data.token);
    } catch (error) {
      errorHandler(error);
    }
  };

  const onSignInSuccess = async (token: string) => {
    Cookies.set("token", token, {
      expires: dayjs().add(23, "hours").toDate(),
    });

    await getUserInfo();
    router.push("/dashboard");
  };

  const register = async (input: RegisterInput): Promise<void> => {
    try {
      const { data } = await axios.post<{ token: string }>(
        `${API_URL}/auth/register`,
        input
      );

      onSignInSuccess(data.token);
    } catch (error) {
      errorHandler(error);
    }
  };

  const getUserInfo = async (): Promise<void> => {
    try {
      const token = Cookies.get("token");
      if (!token) throw new Error("Not Authenticated");

      const { data } = await axios.get<User>(`${API_URL}/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const updateUser = async (input: {
    first_name: string;
    last_name: string;
  }): Promise<void> => {
    const token = Cookies.get("token");
    if (!token) throw new Error();

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await axios.put(`${API_URL}/users/me`, { ...input }, { headers });
      if (user)
        setUser({
          ...user,
          first_name: input.first_name,
          last_name: input.last_name,
        });
    } catch (error) {
      // FIXME log error
      throw error;
    }
  };

  const changePassword = async (password: string) => {
    const token = Cookies.get("token");
    if (!token) throw new Error();

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await axios.patch(
        `${API_URL}/users/me/password`,
        { password },
        { headers }
      );
    } catch (error) {
      // FIXME log error
      throw error;
    }
  };

  const errorHandler = (error: any) => {
    // TODO => add error monitor
    setErrorCode(400);
    setErrorCode(null);
  };

  const logout = async (): Promise<void> => {
    try {
      Cookies.remove("token");
      setUser(null);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        errorCode: errorCode,
        user,
        logout,
        register,
        loading,
        updateUser,
        changePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
