import { Company } from "@models/company.model";
import { createContext, useState, useEffect, useContext } from "react";
import { notification } from "antd";
import axios from "axios";

import AuthContext from "./AuthContext";
import { NEXT_URL } from "@config";

type CompanyContext = {
  company: Company | undefined;
  loading: boolean;
  saving: boolean;
  update: (info: UpdateCompanyDto) => void;
};

type UpdateCompanyDto = {
  name?: string;
  email?: string;
  phone?: string;
  kvk_number?: string;
  vat_number?: string;
  address?: {
    street?: string;
    postal_code?: string;
    city?: string;
  };
};

const CompanyContext = createContext<CompanyContext>({} as any);

export const CompanyProvider: React.FC = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [company, setCompany] = useState<Company>();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (user) loadData();
  }, [user]);

  const loadData = async () => {
    try {
      const resp = await axios.get(`${NEXT_URL}/api/company/get-info`);
      setCompany(resp.data);
    } catch (error: any) {
      notification.error({ message: error.message });
    }

    setLoading(false);
  };

  const update = async (info: UpdateCompanyDto) => {
    setSaving(true);

    try {
      await axios.put(`${NEXT_URL}/api/company/update-info`, info);
      setCompany({ ...company!, ...info });

      notification.success({ message: "Success" });
    } catch (error: any) {
      notification.error({ message: error.message });
    }

    setSaving(false);
  };

  return (
    <CompanyContext.Provider value={{ company, loading, saving, update }}>
      {children}
    </CompanyContext.Provider>
  );
};

export default CompanyContext;
